import { createRoot } from 'react-dom/client'
import App from './App.jsx';
import './index.css';


const reactRoot = createRoot(document.getElementById('root'));

reactRoot.render(
   
      <App />
 
  )
  